import classes from "./Footer.module.css";

import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id={classes["contact-us"]}>
      <div className={classes["social-icons"]}>
        <a href="https://www.facebook.com/light2party/" target="_blank">
          <FaFacebookF />
        </a>
        <a href="https://www.instagram.com/light2party/" target="_blank">
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
