import footer from "../Layout/Footer.module.css";

import classes from "./EventGallery.module.css";

import image41 from "../../assets/41.jpeg";
import mba from "../../assets/mba_22.jpeg";
import love from "../../assets/love.jpeg";
import imageMrMrs from "../../assets/mr-mrs.jpeg";
import image1 from "../../assets/1.jpeg";
import christening from "../../assets/christening-chair.jpeg";

const EventGallery = () => {
  return (
    <section id={classes["event-gallery"]}>
      <div className={classes["grid-images"]}>
        <a href={"#" + footer["contact-us"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${image41}')` }}
          >
            <div className={classes["event-text-wrapper"]}>
              <p>Birthdays</p>
            </div>
          </div>
        </a>

        <a href={"#" + footer["contact-us"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${mba}')` }}
          >
            <div className={classes["event-text-wrapper"]}>
              <p>Corporate Events</p>
            </div>
          </div>
        </a>

        <a href={"#" + footer["contact-us"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${love}')` }}
          >
            <div className={classes["event-text-wrapper"]}>
              <p>Engagements</p>
            </div>
          </div>
        </a>

        <a href={"#" + footer["contact-us"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${imageMrMrs}')` }}
          >
            <div className={classes["event-text-wrapper"]}>
              <p>Weddings</p>
            </div>
          </div>
        </a>

        <a href={"#" + footer["contact-us"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${image1}')` }}
          >
            <div className={classes["event-text-wrapper"]}>
              <p>Baby Showers</p>
            </div>
          </div>
        </a>

        <a href={"#" + footer["contact-us"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${christening}')` }}
          >
            <div className={classes["event-text-wrapper"]}>
              <p>Christenings</p>
            </div>
          </div>
        </a>
      </div>
    </section>
  );
};

export default EventGallery;
