/* 
  Slideshow Component:
  https://www.npmjs.com/package/react-slideshow-image
  https://react-slideshow.herokuapp.com/zoom-out
  npm install react-slideshow-image -S
  */

import { Zoom } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";
import classes from "./Slideshow.module.css";

import img1 from "../../assets/1_colour.jpeg";
import img21 from "../../assets/21.jpeg";
import img30 from "../../assets/30.jpeg";
import img40 from "../../assets/40.jpeg";
import img41 from "../../assets/41-AS.jpeg";

const Slideshow = () => {
  const images = [
    /* "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80", 
    img1,*/
    img21,
    img30,
    img40,
    img41,
  ];

  const zoomOutProperties = {
    duration: 3000,
    transitionDuration: 300,
    infinite: true,
    indicators: true,
    autoplay: true,
    canSwipe: true,
    scale: 1,
    arrows: true,
    /*   responsive: [
      { breakpoint: 1000, settings: {} }
    ] */
  };

  return (
    <div className={classes["slide-container"]}>
      <Zoom {...zoomOutProperties}>
        {images.map((img, index) => (
          <img
            className={classes["slide-img"]}
            key={index}
            alt={img}
            /* style={{ width: "100vw", height: "80vh" }} */
            src={img}
          />
        ))}
      </Zoom>
    </div>
  );
};

export default Slideshow;
