import classes from "./AboutUs.module.css";

const AboutUs = () => {
  return (
    <section id={classes["about-us"]}>
      <h2>About Us</h2>
      <div className={classes["about-us-text"]}>
        <p>
          Established in 2021 Light 2 Party has become one of most sought-after
          event prop rental companies, coming from humble beginnings our founder
          Taijon Ackun started offering a simple and hassle free service to
          balloon artists and event planners in the Manchester area.
        </p>

        <p>
          Perfectionist by nature we are constantly seeking ways we can improve
          our processes, to deliver a high quality, Reliable prop hire service.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
