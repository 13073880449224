import "./App.css";
import Layout from "./components/Layout/Layout";
import AboutUs from "./components/Home/AboutUs";
import EventGallery from "./components/Home/EventGallery";
import Slideshow from "./components/Home/Slideshow";
import ContactForm from "./components/Home/ContactForm";

function App() {
  return (
    <Layout>
      <Slideshow />
      <AboutUs />
      <EventGallery />
      <ContactForm />
    </Layout>
  );
}

export default App;
